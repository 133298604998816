
import { v4 as uuidv4 } from 'uuid';

import {FormConfigurations} from 'kiini/Forms';
import TextField from 'kiini/TextField';
import BooleanField from 'kiini/BooleanField';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';
//import SelectionChoicesField from 'kiini/SelectionChoicesField';
import PrimitiveListField from 'kiini/PrimitiveListField';
import MultipleChoicesField from 'kiini/MultipleChoicesField';
import YearPeriodField, {YearPeriodWidget} from 'kiini/YearPeriodField'
import DefaultTagRoleWidget from './DefaultTagRoleWidget';

import { 
    TextProperty,
    ClosingsPeriod,
    YearPeriod,
} from 'model/contentObjects';

import { WALKINGTRAILTYPE, CYCLINGTRAILTYPE, BOATINGTRAILTYPE, CARTRAILTYPE, HORSETRAILTYPE} from 'model/const';

export const wayOfMovementRoleClazzNames = [
    WALKINGTRAILTYPE, 
    CYCLINGTRAILTYPE, 
    BOATINGTRAILTYPE, 
    CARTRAILTYPE, 
    HORSETRAILTYPE
]

// const isWayOfMovementType = (clazz) => {
//     return clazz === WALKINGTRAILTYPE || clazz === CYCLINGTRAILTYPE || clazz === BOATINGTRAILTYPE || clazz === HORSETRAILTYPE || clazz === CARTRAILTYPE;
// }


export const roleFormsConfigurationData = {
    'content_objects.model.TextProperty':{
        label: "Property",
        fields: [
            {fieldName: "label", label: "Label", widget: TextField},
            {fieldName: "value", label: "Value", widget: TextField, widgetProps: { multiline: true, rows: 3 }},
        ]   
    },
    'content_objects.model.ClosingsPeriod':{
        label: "Property",
        fields: [
            {fieldName: "reason", label: "Reason", widget: TextField, widgetProps: { multiline: true, rows: 3 }},
            {fieldName: "period", label: "Period", widget: YearPeriodField},
            
        ]   
    },
    'content_objects.model.YearPeriod':{
        widget: YearPeriodWidget,
        label: "period",
    },
    'content_objects.roles.ClosingsPeriods': {
        label: "Closing Periods",
        fields: [
            {
                fieldName: "values", 
                label: "Periods", 
                widget: ObjectListWithPanel, 
                widgetProps: {
                    createItem: () => (new ClosingsPeriod(null, {reason: "", period:{fromDay: {day: 1, month:1}, toDay: {day: 1, month:1}}}))
                }
            },
        ]
    },
    'content_objects.roles.OpenPeriods': {
        label: "Open Periods",
        fields: [
            {
                fieldName: "values", 
                label: "Periods", 
                widget: ObjectListWithPanel, 
                widgetProps: {
                    createItem: () => (new YearPeriod(null, {fromDay: {day: 1, month:1}, toDay: {day: 1, month:1}}))
                }
            },
        ]
    },
    'content_objects.roles.WalkingTrail':{
        groups:  [
            {
                label: "Walking Trail",
                fields: [
                    {fieldName: "dogsAllowed", label: "Dogs allowed", widget: BooleanField},
                    {fieldName: "wheelChairAccessible", label: "WheelChair accessible", widget: BooleanField},
                ]   
            }
        ]
    },
    'content_objects.roles.TrailProperties':{
        groups:  [
            {
                label: "Trail",
                fields: [
                    {fieldName: "pavedPercentage", label: "Percentage paved ", widget: TextField},
                    {fieldName: "markedTrail", label: "Marked trail", widget: BooleanField},
                    {fieldName: "shouldStartAtAStartPoint", label: "Should start at startpoint", widget: BooleanField},
                    {fieldName: "directionImportant", label: "Direction important", widget: BooleanField},
                ]   
            }
        ]
    },
    
    'content_objects.roles.NotPublic':{
        widget: DefaultTagRoleWidget,
        label: "",        
    },
    'content_objects.roles.PlayProperties':{
        groups:  [
            {
                label: "Game/Trail Play",
                fields: [
                    {fieldName: "onlyOpenContentObjectOnTrigger", label: "Only open when triggered", widget: BooleanField},
                   
                ]   
            }
        ]       
    },
    'content_objects.roles.TextProperties':{
        groups:  [
            {
                label: "Text properties",
                fields: [
                    {
                        fieldName: "values", 
                        label: "Properties", 
                        widget: ObjectListWithPanel, 
                        widgetProps: {
                            createItem: () => (new TextProperty(null, {id: uuidv4()}))
                        }
                    },
                ]   
            }
        ]       
    },
    'content_objects.roles.SpecialDetails':{
        groups:  [
            {
                label: "Special details",
                fields: [
                    {
                        fieldName: "values", 
                        label: "Properties", 
                        widget: ObjectListWithPanel, 
                        widgetProps: {
                            createItem: () => (new TextProperty(null, {id: uuidv4()}))
                        }
                    },
                ]   
            }
        ]       
    },
    'content_objects.roles.TrailLengths':{
        groups:  [
            {
                label: "Trail lenghts",
                fields: [
                    {fieldName: "description", label: "Length description", widget: TextField},
                    {fieldName: "lengths", label: "Lengths (m)", widget: PrimitiveListField}
                ]   
            }
        ]
    },
    'content_objects.roles.ExperienceDurations':{
        groups:  [
            {
                label: "Durations",
                fields: [
                    {fieldName: "durations", label: "Durations (min)", widget: PrimitiveListField}
                ]   
            }
        ]
    },
    'content_objects.roles.ChildAudience':{
        groups:  [
            {
                label: "Child audience",
                fields: [
                    {fieldName: "childAgeGroups", label: "Child age groups", widget: MultipleChoicesField}
                ]   
            }
        ]
    },
    'content_objects.roles.FamilyAudience':{
        widget: DefaultTagRoleWidget,
        label: ""
    },
    'content_objects.roles.AdultAudience':{
        groups:  [
            {
                label: "Adult audience",
                fields: [
                    {fieldName: "adultAges", label: "Age groups", widget: MultipleChoicesField}
                ]   
            }
        ]
    },
    'content_objects.roles.Environment':{
        groups:  [
            {
                label: "Environment type",
                fields: [
                    {fieldName: "environmentTypes", label: "Types", widget: MultipleChoicesField}
                ]   
            }
        ]
    },
    'content_objects.roles.Nature':{
        widget: DefaultTagRoleWidget,
        label: "",        
    },
    'content_objects.roles.History':{
        widget: DefaultTagRoleWidget,
        label: "",    },
    'content_objects.roles.CulturalHistory':{
        widget: DefaultTagRoleWidget,
        label: "",        
    },
    'content_objects.roles.Food':{
        widget: DefaultTagRoleWidget,
        label: "",        
    },
    'content_objects.roles.Art':{
        widget: DefaultTagRoleWidget,
        label: "",        
    },
    'content_objects.roles.EatDrinkFacilities': {
        widget: DefaultTagRoleWidget,
        label: "",        
    },
    'content_objects.roles.Quest': {
        widget: DefaultTagRoleWidget,
        label: "",        
    },
    'content_objects.roles.DogWalkingTrail':{
        groups:  [
            {
                label: "Dogs Walking Trail",
                fields: [
                    {fieldName: "dogSwimAreaAvailable", label: "Dogs swim area", widget: BooleanField},
                    {fieldName: "dogsLeashedArea", label: "Dogs Leashed Area", widget: BooleanField},
                    {fieldName: "dogsUnLeashedArea", label: "Dogs Unleashed Area", widget: BooleanField},
                    {fieldName: "fencedArea", label: "Fenced Area", widget: BooleanField},
                ]   
            }
        ]
    },
}


export const contentRoleFormConfigurations = new FormConfigurations(roleFormsConfigurationData);
